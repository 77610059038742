.Contact {
    display: inline-block;
    width: 400px;
}

.PhoneBox,
.PhoneBox a,
.PhoneBox a:active,
.PhoneBox a:visited,
.PhoneBox a:hover,
.WarsawAddress,
.WarsawOffice,
.KatowiceOffice {
    display: inline-block;
    vertical-align: top;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-decoration: none;
}
.PhoneBox {
    font-size: 16px;
    width: 190px;
    margin-bottom: 40px;
}
.PhoneBox a,
.PhoneBox a:hover,
.PhoneBox a:active,
.PhoneBox a:visited {
    font-size: 16px;
}
.PhoneBox img {
    margin-right: 8px;
    vertical-align: middle;
}
.WarsawAddress {
    width: 108px;
    margin-right: 56px;
}
.WarsawOffice {
    width: 126px;
    margin-right: 120px;
}
.KatowiceOffice {
    width: 140px;
}

@media (max-width:  1000px) {
    .Contact {
        width: 100%;
    }
    .Contact > h3:first-of-type {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #B4A064;
        text-align: center;
        margin-bottom: 32px;
    }
    .Contact h3:nth-child(2) {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 16px;
    }
    .PhoneBox,
    .WarsawAddress {
        display: block;
        width: 100%;
        margin-bottom: 0;
        margin-right: 0;
    }
    .PhoneBox > div,
    .WarsawAddress > div {
        width: 195px;
        margin: auto;
        position: relative;
    }
    .WarsawAddress img {
        position: absolute;
        left: 35px;
        top: 12px;
    }
    .WarsawAddress > div {
        padding-left: 70px;
    }
    .ContactImg {
        display: block;
        margin: 32px auto;
        max-width: 100%;
    }
    .WarsawOffice h3,
    .KatowiceOffice h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 16px;
    }
    .Offices {
        text-align: center;
    }
    .Offices > div {
        text-align: left;
    }
}

@media (max-width: 426px) {
    .WarsawOffice {
        width: 100%;
        margin-right: 0;
        margin-bottom: 32px;
        text-align: center;
    }
    .WarsawOffice > div {
        text-align: left;
        margin: 0 auto;
        width: 130px;
    }
}