.Wrapper {
    display: inline-block;
    vertical-align: top;
}

.Circle {
    width: 127px;
    height: 127px;
    border-radius: 64px;
    background: white;
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    color: rgba(25, 25, 25, 0.8);
    margin-left: 10px;
    margin-top: 10px;
    line-height: 127px;
}

.CircleWrapper {
    width: 146px;
    height: 146px;
    border-radius: 73px;
    border: 1px solid #B4A064;
    margin-bottom: 12px;
}

.Wrapper p {
    margin: 0;
    width: 147px;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: rgba(25, 25, 25, 0.8);
}


@media (max-width: 600px) {
    .Circle {
        width: 108px;
        height: 108px;
        border-radius: 54px;
        font-size: 28px;
        margin-left: 10px;
        margin-top: 10px;
        line-height: 108px;
    }
    .CircleWrapper {
        width: 128px;
        height: 128px;
        border-radius: 64px;
        margin-bottom: 12px;
    }
    .Wrapper p {
        width: 130px;
    }
}