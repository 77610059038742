.MountainWrapper {
    background-position: bottom -50px center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 100;
    background-color: #08090d;
}
.Mountain {
    margin-top: -36px;
    min-height: 100vh;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 300px;
    padding-left: 160px;
    padding-right: 160px;
    box-sizing: border-box;
}

.Mountain img {
    cursor: pointer;
}

.Mountain.MenuOpened {
    padding-top: 500px;
}

.Mountain h1 {
    max-width: 905px;
    margin-top: 0;
    margin-bottom: 41px;
    font-weight: normal;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.TopBar {
    display: none;
}

@media (max-width: 1000px) {
    .TopBar {
        display: block;
    }
    .Mountain {
        margin-top: 0;
        text-align: center;
        padding: 170px 24px 0 24px;
    }
    .Mountain h1 {
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        max-width: 366px;
        margin: 0 auto 24px auto;
    }
    .Mountain img {
        width: 100%;
        max-width: 340px;
    }
    .MountainWrapper {
        background-position-x: calc(50% - 600px);
        background-position-y: top;
    }
}

