.FooterWrapper {
    background: black;
}
.Footer {
    padding: 64px 80px 64px 160px;
    color: white;
    text-align: left;
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
}

.Footer a,
.Footer a:active,
.Footer a:visited {
    color: white;
    text-decoration: none;
}

.Footer a:hover {
    color: #B4A064;
}

.Footer h3 a {
    cursor: pointer;
}

.Col {
    display: inline-block;
    width: 310px;
    vertical-align: top;
}

.Col > h3:first-of-type {
    margin-bottom: 24px;
}
.Footer > div:first-of-type {
    margin-right: 330px;
}

.Footer > div:first-of-type > h3:first-of-type {
    margin-bottom: 24px;
}

.Inline {
    display: inline-block;
    margin-right: 42px;
}
.Inline:last-of-type {
    margin-right: 0;
}

.Footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Footer ul li {
    max-width: 142px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.Footer ul li a {
    cursor: pointer;
}

.Footer ul li:last-of-type {
    margin-bottom: 0;
}

.Element {
    display: inline-block;
}

.Languages {
    position: absolute;
    right: 0;
    bottom: 30px;
}
.Languages a:hover,
.Languages a:active,
.Languages a:visited,
.Languages a:focus,
.Languages a {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #B4A064;
    text-decoration: none;
}
.Languages button {
    margin-left: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    border: 0;
    padding: 0;
    background: transparent;
}

@media (max-width:  1400px) {
    .Footer > div:first-of-type {
        margin-right: 230px;
    }
}
@media (max-width:  1200px) {
    .Footer > div:first-of-type {
        margin-right: 130px;
    }
}
@media (max-width:  1100px) {
    .Footer > div:first-of-type {
        margin-right: 30px;
    }
}

@media (max-width:  1000px) {
    .Element {
        text-align: left;
    }
    .Col {
        display: none;
    }
    .Footer {
        text-align: center;
        padding: 56px 24px 64px 24px;
    }
    .Footer > div:first-of-type {
        margin-right: 0;
    }
}
