.TopBarWrapper {
    height: 32px;
    background: rgba(26, 26, 26, 0.6);
    z-index: 1200;
    position: relative;
}
.TopBar {
    text-align: right;
    color: white;
    max-width: 1440px;
    margin: 0 auto;
}

.TopBar a {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-decoration: none;
}
.TopBar a:active,
.TopBar a:visited,
.TopBar a:hover {
    text-decoration: none;
}
.TopBar a:hover {
    color: #B4A064;
}
.TopBar a:last-of-type {
    margin-right: 160px;
}
.TopBar a:first-of-type {
    margin-right: 64px;
}


@media (max-width: 1000px) {
    .TopBarWrapper {
        display: none;
    }
}