.CookiesPopup {
    margin: 15px 0;
    padding: 7px 15px;
    text-align: left;
    max-height: 500px;
}
.CookiesPopup ul {
    list-style: circle;
}
.PopupClose {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
    color: #B4A064;
}
.CookiesPopup a,
.CookiesPopup a:active,
.CookiesPopup a:visited {
    font-weight: bold;
    color: black;
    text-decoration: none;
}
.CookiesPopup a:hover {
    color: #B4A064;
}