.Kayak {
    font-size: 0;
    background-color: white;
    max-width: 1920px;
}

.Kayak a,
.Kayak a:active,
.Kayak a:visited,
.Kayak a:hover {
    color: #B4A064;
    text-decoration: none;
    font-weight: 500;
}

.KayakImg {
    min-height: 100vh;
    background-color: #252527;
    display: table-cell;
}

.KayakImg img {
    display: block;
    width: auto;
    height: 100vh;
}

.Content {
    display: table-cell;
    vertical-align: top;
    min-height: 100vh;
    padding: 84px 160px 64px 64px;
    box-sizing: border-box;
}

.Content h2 {
    margin-bottom: 24px;
}

.Content p {
    margin-top: 0;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: rgba(25, 25, 25, 0.8);
}

@media (max-width: 1000px) {
    .KayakImg {
        display: block;
        min-height: auto;
    }
    .Kayak {
        padding-left: 0;
        padding-right: 0;
    }
    .Kayak img {
        height: auto;
        width: 100%;
        display: block;
    }
    .Kayak p,
    .Kayak h2 {
        padding-left: 24px;
        padding-right: 24px;
    }
    .Content {
        display: block;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        max-height: unset;
    }
    .Kayak h2 {
        text-align: center;
    }
}