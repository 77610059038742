.Cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 12px 160px;
    text-align: center;
    z-index: 9999;
    opacity: 1;
    transition: opacity 200ms ease-out, bottom 10ms linear 500ms;
}
.Cookies.Hide {
    opacity: 0;
    bottom: -100%;
}

.Cookies button.Link {
    color: #B4A064;
    padding: 0;
    border: 0;
    background: none;
    margin-left: 0;
}

.Cookies button {
    background: #c4c4c4;
    border: 0;
    padding: 5px 24px;
    margin-left: 80px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .Cookies {
        padding: 12px 24px;
    }
    .Cookies button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
    }
}
