.AboutWrapper {
    background-color: white;
    background-position: bottom right;
    background-repeat: no-repeat;
}
.About {
    box-sizing: border-box;
    padding: 136px 160px;
    max-width: 1440px;
    margin: 0 auto;
    min-height: 100vh;
}

.About img {
    max-width: 100%;
    display: block;
}

.About h2 {
    margin-bottom: 24px;
}

.Col {
    display: inline-block;
    vertical-align: top;
    width: calc(100%/2 - 16px);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: rgba(25, 25, 25, 0.8);
    margin-bottom: 112px;
}
.Col:first-of-type {
    margin-right: 32px;
}

.CircleLine {
    text-align: center;
}
.CircleLine > div {
    margin-right: 64px;
}
.CircleLine > div:last-of-type {
    margin-right: 0;
}

.Divider1 {
    height: 72px;
    display: none;
}

.Divider2 {
    height: 72px;
    display: none;
}

@media (max-width: 1120px) {
    .CircleLine {
        width: 370px;
        margin: 0 auto;
    }
    .CircleLine > div {
        margin-right: 42px;
        margin-bottom: 32px;
    }
    .CircleLine > div:nth-child(2n) {
        margin-right: 0;
    }
}

@media (max-width: 1000px) {
    .About {
        padding: 56px 24px;
    }
    .About h2 {
        text-align: center;
    }
    .Col {
        display: block;
        width: 100%;
    }
    .Col:first-of-type {
        margin-right: 0;
        margin-bottom: 24px;
    }
}

@media (max-width: 600px) {
    .CircleLine {
        width: 305px;
    }
}

@media (max-width: 360px) {
    .CircleLine {
        width: 130px;
    }
    .CircleLine > div {
        margin-right: 0;
    }
}
