.MenuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.MenuWrapper.Closed {
    mix-blend-mode: difference;
}

.MenuOpenedWrapper {
    background: white;
    max-width: 1920px;
    margin: 0 auto;
}
.MenuOpened {
    padding: 60px 160px 28px 160px;
    max-width: 1440px;
    box-sizing: border-box;
    margin: auto;
    color: rgba(25, 25, 25, 0.8);
}
.MenuOpened.NotTop {
    padding: 18px 160px;
}
.MenuOpened h3 {
    color: #191919;
}
.MenuOpened a,
.MenuOpened a:visited,
.MenuOpened a:active {
    color: rgba(25, 25, 25, 0.8);
    text-decoration: none;
}
.MenuOpened a:hover {
    color: #B4A064;
}

.BlackLogo {
    margin-right: 32px;
    margin-bottom: 40px;
    cursor: pointer;
}
.MenuOpened a.Button,
.MenuOpened a.Button:hover,
.MenuOpened a.Button:focus,
.MenuOpened a.Button:visited,
.MenuOpened a.Button:active,
.MenuOpened button {
    vertical-align: top;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #191919;
    opacity: 0.5;
    margin: 2px 0 0 0;
    padding: 0;
    border: 0;
    background: none;
    width: 21px;
    height: 24px;
    display: inline-block;
}
.MenuOpened button {
    color: #B4A064;
    margin-left: 16px;
}
.CloseMenu {
    float: right;
    cursor: pointer;
}

.MenuClosed {
    margin: 62px auto 0 auto;
    padding: 0 160px;
    text-align: right;
    max-width: 1440px;
    box-sizing: border-box;
    transition: margin 500ms ease-out;
}
.MenuClosed.NotTop {
    margin-top: 16px;
}

.MenuClosed > div {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}
.MenuClosed > div img {
    vertical-align: baseline;
    margin-left: 14px;
    position: relative;
    top: -1px;
}

.LogoCLosedWhite {
    float: left;
    width: 212px;
    cursor: pointer;
}

.Navigation {
    float: right;
}

.Navigation h3:first-of-type {
    margin-bottom: 40px;
}

.Navigation ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: top;
}

.Navigation ul li {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #191919;
    margin-bottom: 32px;
}
.Navigation ul li:last-of-type {
    margin-bottom: 0;
}
.Navigation ul li .Active {
    color: #b4a064;
}

.Services {
    display: inline-block;
    vertical-align: top;
    margin-top: 56px;
    width: 272px;
}

.Services ul {
    padding-left: 43px;
    margin-right: 50px;
}

.Services ul li {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #191919;
    margin-bottom: 8px;
    position: relative;
}

.Navigation ul li a,
.Services ul li a {
    cursor: pointer;
}

.Navigation ul li a:hover,
.Services ul li a:hover {
    color: #b4a064;
}

.Services ul li:first-of-type:before {
    content: '';
    width: 16px;
    border-bottom: solid 2px #b4a064;
    position: absolute;
    left: -32px;
    top: 10px;
    z-index: 1;
}

.LogoClosedBlack {
    float: left;
    width: 212px;
    display: none;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .LogoClosedBlack {
        display: block;
    }
    .LogoCLosedWhite {
        display: none;
    }
    .MenuWrapper {
        background-color: white;
    }
    .MenuClosed {
        padding: 27px 20px;
        margin-top: 0;
    }
    .MenuWrapper.Closed {
        mix-blend-mode: normal;
    }
    .MenuClosed.NotTop {
        margin-top: 0;
    }
    .MenuOpened.NotTop {
        padding: 24px;
    }

    .MenuOpened > div:first-child,
    .MenuOpened > div:nth-child(2),
    .MenuOpened h3 {
        display: none;
    }
    .Services ul {
        display: none;
    }
    .MenuOpened {
        padding: 24px;
    }
    .Navigation {
        float: none;
    }
    .Navigation ul li {
        font-size: 24px;
        line-height: 32px;
    }
    .Services {
        display: none;
    }
}