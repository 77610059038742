.BottomBar {
    height: 67px;
    background: white;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #191919;
    padding-top: 14px;
    box-sizing: border-box;
    position: relative;
}
.BottomBar a:hover,
.BottomBar a:visited,
.BottomBar a:active,
.BottomBar a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #191919;
    text-decoration: none;
}

.BottomBar button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #191919;
}

.BottomBar div {
    display: inline-block;
}

.BottomBar img {
    vertical-align: middle;
    margin: 0 24px;
    cursor: pointer;
}

@media (max-width:  1000px) {
    .BottomBar {
        padding: 24px;
        height: auto;
    }
    .BottomBar img {
        margin-bottom: 24px;
    }
    .BottomBar div {
        display: block;
        margin-bottom: 8px;
    }
}