* {
  font-family: 'Catamaran', sans-serif;
}

body {
  margin: 0;
}

h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #B4A064;
  margin-top: 0;
  margin-bottom: 0;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 8px;
}

.simplebar-scrollbar::before {
  background-color: #b4a064 !important;
  top: 0!important;
  bottom: 0!important;
  left: 1px!important;
  opacity: 1!important;
}
.simplebar-track.simplebar-vertical:before {
  position: absolute;
  content: '';
  background: rgba(218, 218, 218, 0.5);
  width: 4px;
  left: 3px;
  border-radius: 4px;
  top: 0;
  bottom: 0;
}