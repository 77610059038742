.MountainBottomWrapper {
    background-color: #18191d;
    background-position: top center;
    background-repeat: no-repeat;
}
.MountainBottom {
    min-height: 100vh;
    box-sizing: border-box;
    padding: 0 0 64px 160px;
    max-width: 1440px;
    margin: 0 auto;
}

.MountainBottom h2 {
    margin-bottom: 24px;
}

.SideMenuWrapper {
    display: inline-block;
    position: relative;
}

.SideMenuLine {
    position: absolute;
    left: -16px;
    width: 2px;
    height: 24px;
    background-color: #b4a064;
    transition: top 500ms ease-out;
    top: 0;
}

.SideMenu {
    padding-top: 240px;
    width: 340px;
    display: inline-block;
    vertical-align: top;
}

.SideMenu ul {
    padding: 0;
    margin: 0 0 0 -18px;
}

.SideMenu ul li,
.SideMenu ul li a:hover,
.SideMenu ul li a:visited,
.SideMenu ul li a:active,
.SideMenu ul li a:focus,
.SideMenu ul li a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 16px;
    padding-left: 18px;
    cursor: pointer;
    position: relative;
    left: 0;
    transition: left 200ms linear;
    text-decoration: none;
}
.SideMenu ul:not(.Submenu) > li {
    list-style: none;
}
.SideMenu ul.Submenu > li {
    padding-left: 5px;
}

.SideMenu ul li.Inactive {
    padding-left: 40px;
}

.SideMenu ul li:not(.Inactive):hover {
    font-size: 16px;
    left: 20px;
}

.SideMenu ul li.Active {
    font-weight: 600;
    font-size: 16px;
}

.SideMenu ul li ul {
    padding-left: 16px;
}

.SideMenu ul li ul li {
    margin-bottom: 16px;
}
.SideMenu ul li ul li:last-of-type {
    margin-bottom: 24px;
}
.AccountingFull ul {
    padding-left: 20px;
}
.AccountingFull ul li {
    padding-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

.Text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 340px - 160px);
    padding-left: 70px;
    box-sizing: border-box;
}

.Text.AccountingSmall {
    padding-top: 240px;
}
.Text.AccountingFull {
    margin-top: 140px;
}

.Text p {
    max-width: 704px;
    margin: 0 0 16px;
}

.Text h2:not(:first-of-type) {
    margin-top: 24px;
}
.Text h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #b4a064;
    margin-bottom: 8px;
}
.Text > div:first-of-type > h2:first-of-type {
    margin-bottom: 24px;
}
.Text h2 span {
    margin-left: 56px;
    margin-bottom: 16px;
    display: inline-block;
}

.Text button {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #B4A064;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    left: 0;
    transition: left 200ms linear;
}

.Text button.ReadMore:hover {
    left: 20px;
}

@media (max-width: 1000px) {
    .SideMenu {
        padding-top: 56px;
        display: block;
        text-align: center;
        margin: 0 auto;
        width: 100%;
    }
    .MountainBottom {
        padding-left: 0;
    }
    .Text {
        padding: 0 24px;
        width: 100%;
        margin: 40px auto 0 auto;
        text-align: center;
    }
    .Text ul li {
        text-align: left;
    }
    .Text p {
        text-align: left;
        margin-bottom: 32px;
        max-width: 100%;
    }
    .Text.AccountingFull {
        padding-top: 0;
    }
    .Text.AccountingSmall {
        padding-top: 0;
    }
    button img {
        display: block;
        margin: 4px auto 0 auto;
    }
    .SideMenu ul {
        margin: 0;
    }
    .SideMenu ul li:not(.Inactive):hover {
        left: 0;
        font-size: 16px;
    }
    .SideMenu ul.Submenu li {
        padding-left: 40px;
        margin-bottom: 0;
    }
    .SideMenu ul li {
        border: 1px solid #b4a064;
        padding: 8px 16px;
        margin: 0;
        border-top: 0;
        width: 100%;
        max-width: 295px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: white;
        box-sizing: border-box;
        text-align: left;
    }
    .SideMenu ul li.Inactive {
        padding: 0;
    }
    .SideMenu ul li ul {
        padding: 0;
    }
    .SideMenu ul li ul li {
        border-left: 0;
        border-right: 0;
        padding-left: 48px;
        list-style: none;
    }
    .SideMenu ul li ul li:last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
    }
    .Text button.ReadMore:hover {
        left: 0;
    }
    .SideMenuLine {
        display: none;
    }
    .Dropdown {
        min-width: 295px;
        width: 100%;
        max-width: 295px;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: white;
        border: 1px solid #b4a064;
        padding: 8px 16px;
        box-sizing: border-box;
    }
    .Dropdown img {
        float: right;
        position: relative;
        top: 10px;
    }
    .SideMenu .Dropdown.Open + ul {
        display: block;
    }
    .SideMenu .Dropdown + ul {
        display: none;
    }
    .Text.AccountingFull {
        margin-top: 40px;
        max-height: unset;
    }
}